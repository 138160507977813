.order-header__title {
  margin: 0;
  padding: 20px;
}

.order-header__name {
  font-weight: 400;
}

.order-header__properties {
  border: 1px solid #e6ebf1;
  border-radius: 8px;
  margin: 0 20px 10px;
  padding: 10px;
}

.order-header__properties--light-theme {
  background-color: white;
}
