.order-loading__container {
  height: 290px;
}

.order-loading__spinner {
  margin-bottom: 10px;
  font-size: 30px;
}

.order-loading__status {
  font-size: 20px;
  animation: text 12s 1;
}

@keyframes text {
  0% {
    opacity: 0;
  }

  12.5% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  37.5% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  62.5% {
    opacity: 1;
  }

  75% {
    opacity: 0;
  }

  87.5% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}
