.orders-viewer-order .ant-card {
    height: 100%;
}
.orders-viewer-order {
    height: 100%;
    margin-bottom: -20px;
    padding-bottom: 20px;
}

.ant-design-table {
    margin-top: 25px;
}
