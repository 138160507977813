.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sorting {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sorting-icons {
  padding-right: 1.5rem;
}

.arrow-icon {
  border-radius: 0.3rem;
}

.arrow-icon--up-active,
.arrow-icon--down-active {
  padding: 0.5rem;
  background-color: #3e82f7 !important;
  color: white;
}

.arrow-icon--up-active {
  margin-left: 0.5rem;
}

.arrow-icon--down-active {
  margin-right: 0.5rem;
}

.sorting-type {
  width: 180px;
}
