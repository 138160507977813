.root {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.title.title {
  margin: 0;
  font-size: 14px;
  line-height: 40px;
}

.vendors {
  order: 2;
  margin-top: 5px;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .root {
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .title.title {
    margin-right: 1rem;
    margin-bottom: 0;
  }

  .vendors {
    order: 1;
    margin-top: 0;
  }

  .reset-button {
    order: 2;
    margin-left: 1rem;
    min-width: 130px;
  }
}
