.root {
  display: flex;
  align-items: center;
  padding: 16px;
}

.option {
  display: flex;
  align-items: center;
}

.option-title {
  font-size: 16px;
  font-weight: 500;
}

.root .payment-type {
  margin-left: 16px;
  margin-right: 32px;
  width: 160px;
}

.root .payment-delay {
  margin-left: 16px;
  width: 85px;
}
