.root {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.person {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.person + .person {
  margin-top: 5px;
}

.person__name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.email-link {
  margin-left: 2px;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}
