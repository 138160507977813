.bell {
    background-color: #E6EBF1;
    border-radius: 12px;
    height: 35px;
    margin-top: 15px;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.bell > p {
    margin: 0 0 0 10px;
}

/* .introductoryList {
    color: #72849a;
} */

/* .modalPoll .mantine-Modal-title {
    font-size: 26px;
    line-height: 30px;
    color: rgb(26, 51, 83);
    font-weight: 500;
}

.radioPoll > .mantine-1lumg83 {
    max-width: 285px;
    margin: 0 auto;
}

.radioPoll > .mantine-1lumg83 > div {
    display: flex;
    flex-direction: column-reverse;
    margin-right: 10px;
}

.radioPoll > .mantine-1lumg83 > div label {
    margin: 0 auto 5px auto;
} */