.search-field {
  margin-bottom: 1rem;
}

.search-result-filter {
  margin-top: 1rem;
}

.search-result {
  margin-top: 1rem;
}

.analogs-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.analogs-loading__icon {
  font-size: 24px;
}

.analogs-header {
  margin-top: 2rem;
}

.analogs {
  margin-top: 1rem;
}
